<template>
<div>
    <div class="text-right mb-1">
    <b-button
        variant="primary"
        pill
        @click="createBlogCategory"
        v-if="hasPermission('create_blog_category')"
    >
        <feather-icon icon="PlusIcon" class="mr-50" />
        <span class="align-middle">Create</span>
    </b-button>
    </div>
    <b-card>
    <!-- <b-row class="mb-1" align-v="center">
        <b-col md="7">
        <div v-if="searchType">
            <b-form-group
            label="Name"
            label-for="name"
            class="w-50"
            v-if="searchType.value === 1"
            >
            <b-input-group>
                <template #prepend> </template>
            </b-input-group>
            <b-form-input id="name" v-model="name" placeholder="Category Name" />
            </b-form-group>
        </div>
        </b-col>
        <b-col md="3">
        <b-form-group label="Search Type" label-for="searchType">
            <v-select
            id="searchType"
            v-model="searchType"
            :options="searchTypes"
            placeholder="Search Type"
            label="name"
            />
        </b-form-group>
        </b-col>
        <b-col md="2">
        <b-button variant="primary" pill @click="search">
            <feather-icon icon="SearchIcon" class="mr-50" />
            <span class="align-middle">Search</span>
        </b-button>
        </b-col>
    </b-row> -->

    <!-- Filters Below  -->
    <b-row class="mb-1" align-v="center">
        <b-col md="7">
          <div v-if="searchType">
            <b-form-group
              label="Name"
              label-for="name"
              class="w-50"
              v-if="searchType.value === 1"
            >
              <b-form-input
                id="name"
                v-model="name"
                placeholder="Name"
              />
            </b-form-group>
            
          </div>
        </b-col>
        <b-col md="3">
          <b-form-group label="Search Type" label-for="searchType">
            <v-select
              id="searchType"
              v-model="searchType"
              :options="searchTypes"
              placeholder="Search Type"
              label="name"
            />
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-button variant="primary" pill @click="search">
            <feather-icon icon="SearchIcon" class="mr-50" />
            <span class="align-middle">Search</span>
          </b-button>
        </b-col>
      </b-row>
      <!-- Filters above  -->
    <b-table
        responsive="sm"
        :fields="fields"
        :items="blogCategories"
        details-td-class="m-0 p-0"
        small
        v-if="hasPermission('create_blog_category')"
        :per-page="0"
    >
    <template #cell(serial)="row">
        {{ (currentPage - 1) * perPage + row.index + 1 }}
      </template>
      <template #cell(name)="row">
        {{ truncate(row.item.name, 20) }} 
      </template>
        <template #cell(created_at)="row">
        {{ row.item.created_at }}
        </template>
        <template #cell(updated_at)="row">
        {{ row.item.updated_at }}
        </template>
        <template #cell(created_by_data)="row">
        {{
            row.item.created_by
        }}
        </template>
        <template #cell(updated_by_data)="row">
        {{
            row.item.updated_by
        }}
        </template>
        <template #cell(manage)="row">
        <b-button
            variant="info"
            pill
            size="sm"
            class="mr-1"
            @click="editBlogCategory(row.item)"
            v-if="hasPermission('update_blog_category')"
        >
            Edit
        </b-button>
        <b-button
            variant="danger"
            pill
            size="sm"
            @click="removeBlogCategory(row.item)"
            v-if="hasPermission('delete_blog_category')"
        >
            Delete
        </b-button>
        </template>
    </b-table>
    <b-pagination
        size="md"
        :total-rows="totalItems"
        v-model="currentPage"
        :per-page="perPage"
    ></b-pagination>
    </b-card>
    <BlogCategoryCreateModal
    @modalClosed="onModalClosed"
    :key="`create-${blogCategoryCreateModalCount}`"
    />
    <BlogCategoryEditModal
    :blogcategory="blogcategory"
    @modalClosed="onModalClosed"
    :key="`edit-${blogCategoryEditModalCount}`"
    />
</div>
</template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import util from "@/util.js";
  import BlogCategoryCreateModal from "@/components/admin/blog_category/BlogCategoryCreateModal.vue";
  import BlogCategoryEditModal from "@/components/admin/blog_category/BlogCategoryEditModal.vue";
  
  export default {
    components: {
      BlogCategoryCreateModal,
      BlogCategoryEditModal
    },
    data() {
    return {
      fields: [
        { key: "serial", label: "S. No" },
        { key: "name", label: "Name" },
        { key: "created_at", label: "Created At" },
        { key: "created_by_data", label: "Created By" },
        { key: "updated_at", label: "Updated At" },
        { key: "updated_by_data", label: "Updated By" },
        { key: "manage", label: "Action" },
      ],
      currentPage: 1,
      perPage: 0,
      totalItems: 0,
      blogCategories: [],
      blogcategory: null,
      blogCategoryCreateModalCount: 0,
      blogCategoryEditModalCount: 0,
      searchTypes: [
          { value: 1, name: "Name" },
        ],
        searchType: null,
        name: "",
    };
    },
    mixins: [util],
    created() {
        this.searchType = this.searchTypes[0];
    },
    async mounted() {
      await this.fetchPaginatedData();
    },
    methods: {
      ...mapActions({
        getBlogCategory: "appData/getBlogCategory",
        deleteBlogCategory: "appData/deleteBlogCategory",
      }),
      async search() {
            if (this.searchType) {
                switch (this.searchType.value) {
                    case 1:
                        break;
                }
            } else {
                this.name = "";
            }
            this.currentPage = 1;
            await this.fetchPaginatedData();
        },
      async fetchPaginatedData() {
        try {
          const res = await this.getBlogCategory({
            pageNumber: this.currentPage,
            name: this.name ? this.name : "",
          });
          this.blogCategories = res.data.results;
          this.totalItems = res.data.count;
          this.perPage = res.data.per_page;
        } catch (error) {
          this.displayError(error);
        }
      },
      createBlogCategory() {
        this.blogCategoryCreateModalCount += 1;
        this.$nextTick(() => {
          this.$bvModal.show("blogcategory-create-modal");
        });
      },
      editBlogCategory(blogcategory) {
        this.blogcategory = blogcategory;
        this.blogCategoryEditModalCount += 1;
        this.$nextTick(() => {
          this.$bvModal.show("blogcategory-edit-modal");
        });
      },
      async removeBlogCategory(blogcategory) {
        this.blogcategory = blogcategory;
  
        this.$swal({
          title: "Are you sure?",
          icon: "warning",
          showCloseButton: true,
          showCancelButton: true,
          confirmButtonText: "Confirm",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-danger ml-1",
          },
          buttonsStyling: false,
        }).then(async (result) => {
          if (result.value) {
            try {
              const res = await this.deleteBlogCategory({
                pk: this.blogcategory.id,
              });
              if (res.status === 200) {
                this.$swal({
                  title: "Blog Category deleted successfully",
                  icon: "success",
                });
                if (
                  (this.totalItems - 1) % this.perPage === 0 &&
                  this.currentPage !== 1
                ) {
                  this.currentPage -= 1;
                } else {
                  await this.fetchPaginatedData();
                }
              }
            } catch (error) {
              this.show = false;
              this.displayError(error);
            }
          } else {
            this.show = false;
          }
        });
      },
      async onModalClosed() {
        await this.fetchPaginatedData();
      },
    },
    computed: {
      ...mapGetters({
        hasPermission: "appData/hasPermission",
      }),
    },
    watch: {
      currentPage: async function (val) {
        await this.fetchPaginatedData();
      },
    },
  };
  </script>
    
  <style>
  .bg1 {
    color: red;
    font-weight: normal;
  }
  </style>
  