<template>
<b-modal
    id="blogcategory-create-modal"
    title="Blog Category Create Modal"
    centered
    hide-footer
    size="lg"
    @hidden="reset"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
>
    <template #modal-title>
    <h2 class="m-0">Blog Category Create</h2>
    </template>
    <validation-observer ref="BlogCategoryCreateFormValidation">
    <b-form @submit.prevent="submit">
        <b-form-group label-for="name">
        <template #label>
            Category Name <span class="text-danger">*</span>
        </template>
        <b-form-input
            id="name"
            v-model="name"
            v-restrict="regex"
            placeholder="Category Name"
            required
            @keydown="checkMaxLength($event,'name', 200)"
        />
        </b-form-group>

        <b-form-group label-for="status">
        <template #label>
            Status <span class="text-danger">*</span>
        </template>
        <b-form-select
            id="status"
            v-model="status"
            :options="options"
            required
        ></b-form-select>
        </b-form-group>

        <b-form-group class="mt-2 text-right">
        <b-button
            type="submit"
            variant="primary"
            pill
            class="mr-1"
        >
            Save
        </b-button>
        <b-button
            type="reset"
            variant="outline-secondary"
            pill
            @click="reset"
        >
            Reset
        </b-button>
        </b-form-group>
    </b-form>
    </validation-observer>
</b-modal>
</template>
  
  <script>
  import { mapActions, mapGetters } from "vuex";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import { required } from "@validations";
  import util from "@/util.js";
  import { BButton, BForm, BFormGroup, BFormInput } from 'bootstrap-vue';
  
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,    
      BButton,
      BForm,
      BFormGroup,
      BFormInput
    },
    mixins: [util],
    data() {
      return {
        required,
        name: "",
        status: "",
          options: [
            { value: "", text: "-- Select --" },
            { value: true, text: "Enable" },
            { value: false, text: "Disable" },
          ],
          regex: /^[a-zA-Z ]*$/,
        };
    },
    methods: {
      ...mapActions({
        createBlogCategory: "appData/createBlogCategory"
      }),
      checkMaxLength(event, fieldName, maxLength) {
            if (
              event.target.value.length >= maxLength &&
              event.key !== "Backspace" &&
              event.key !== "Delete"
            ) {
              event.preventDefault();
              this[fieldName] = event.target.value.slice(0, maxLength);
            }
          },
      async submit() {
        try {
          const formData = new FormData();
          formData.append('name', this.name);
          formData.append('status', this.status);
          formData.append('created_by', this.getLoggedInUser.id);
          formData.append('updated_by', this.getLoggedInUser.id);
  
          const res = await this.createBlogCategory(formData);
          if (res.status === 200) {
            this.$swal({
              title: "Blog Category is created successfully",
              icon: "success",
            });
            this.reset();
            this.$nextTick(() => {
              this.$bvModal.hide("blogcategory-create-modal");
            });
            this.$emit("modalClosed");
          }
        } catch (error) {
          this.displayError(error);
        }
      },
      reset() {
        this.name = "";
      }
    },
    computed: {
      ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
    },
  };
  </script>
  
  <style></style>
  