<template>
<b-modal
    id="blogcategory-edit-modal"
    title="Blog Category Edit Modal"
    centered
    hide-footer
    size="lg"
    @hidden="reset"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
>
    <template #modal-title>
    <h2 class="m-0">Blog Category Edit</h2>
    </template>
    <validation-observer ref="blogcategoryEditFormValidation">
    <b-form @submit.prevent="submit">
        <b-form-group label-for="name">
        <template #label>
            Category Name <span class="text-danger">*</span>
        </template>
        <b-form-input
            id="name"
            v-model="name"
            v-restrict="regex"
            placeholder="Category Name"
            required
            @keydown="checkMaxLength($event,'name', 200)"      
        />
        </b-form-group>

        <b-form-group label-for="status">
        <template #label>
            Status <span class="text-danger">*</span>
        </template>
        <b-form-select
            id="status"
            v-model="status"
            :options="options"
            required
        ></b-form-select>
        </b-form-group>

        <b-form-group class="mt-2 text-right">
        <b-button
            type="submit"
            variant="info"
            pill
            class="mr-1"
        >
            Update
        </b-button>
        </b-form-group>
    </b-form>
    </validation-observer>
</b-modal>
</template>

  <script>
  import { mapActions, mapGetters } from "vuex";
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import util from "@/util.js";
  import { BButton, BForm, BFormGroup, BFormInput, BFormSelect } from 'bootstrap-vue';
  
  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      BButton,
      BForm,
      BFormGroup,
      BFormInput,
      BFormSelect // Add BFormSelect here
    },
    mixins: [util],
    props: {
      blogcategory: Object,
    },
    data() {
      return {
        name: "",
        status: "", // Initialize status
        options: [
          { value: "", text: "-- Select --" },
          { value: true, text: "Enable" },
          { value: false, text: "Disable" },
        ],
        regex: /^[a-zA-Z ]*$/,
      };
    },
    async mounted() {
      if (this.blogcategory && typeof this.blogcategory === 'object') {
        this.name = this.blogcategory.name;
        this.status = this.blogcategory.status;
      }
    },
    methods: {
      ...mapActions({
        updateBlogCategory: "appData/updateBlogCategory",
      }),
      checkMaxLength(event, fieldName, maxLength) {
            if (
              event.target.value.length >= maxLength &&
              event.key !== "Backspace" &&
              event.key !== "Delete"
            ) {
              event.preventDefault();
              this[fieldName] = event.target.value.slice(0, maxLength);
            }
          },
      async submit() {
        const formData = new FormData();
        formData.append('name', this.name);
        formData.append('status', this.status);
        formData.append('updated_by', this.getLoggedInUser.id);
  
        try {
          const res = await this.updateBlogCategory({
            payload: formData,
            pk: this.blogcategory.id,
          });
          if (res.status === 200) {
            this.$swal({
              title: "Blog Category updated successfully",
              icon: "success",
            });
            this.reset();
            this.$nextTick(() => {
              this.$bvModal.hide("blogcategory-edit-modal");
            });
            this.$emit("modalClosed");
          }
        } catch (error) {
          this.displayError(error);
        }
      },
      reset() {
        this.name = this.blogcategory.name || "";
        this.status = this.blogcategory.status || ""; // Reset status
      },
    },
    computed: {
      ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
    },
  };
  </script>
  
  <style>
  </style>
  